import {
  PublicClientApplication,
  type AccountInfo,
  type RedirectRequest,
} from "@azure/msal-browser";

import { reactive } from "vue";
import { AppKey } from "@/classes/AppKey";

const appKey = new AppKey();
await appKey.fetch();

export const msalConfig = {
  auth: {
    clientId: appKey.getClientId(),
    authority: `https://login.microsoftonline.com/${appKey.getTenantId()}`,
    redirectUri: window.location.origin, // Replace with your actual redirect URI
    postLogoutUri: window.location.origin,
  },
  cache: {
    cacheLocation: "sessionStorage", //  This configures where you cache will be stored
    storeAuthStateInCookie: false,
  },
};

export const graphScopes: RedirectRequest = {
  scopes: ["user.read", "openid", "profile", "group.Read.All"],
};

export const state = reactive({
  isAuthenticated: false,
  user: null as AccountInfo | null,
});

export const myMSALObj = new PublicClientApplication(msalConfig);
