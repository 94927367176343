export class AppKey {
  private clientId: string;
  private tenantId: string;

  public constructor() {
    this.clientId = "";
    this.tenantId = "";
  }

  api(): string {
    return `https://contacts-api.home.okkara.net/appkey/${window.location.host}`;
  }

  public getClientId(): string {
    return this.clientId;
  }

  public getTenantId(): string {
    return this.tenantId;
  }

  async fetch() {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const resp = await fetch(this.api(), requestOptions)
      .then((res) => res.json())
      .then((json) => {
        if (json?.status === "SUCCESS") {
          const data = json.data;
          if (data?.status === "SUCCESS") {
            this.clientId = data.result.client_id;
            this.tenantId = data.result.tenant_id;
          }
        }
      })
      .catch((err) => console.log(err.message));
    return resp;
  }
}
